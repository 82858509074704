import * as React from 'react';
import { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class ConsentDialogForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      open: true
    };

    // Bind Class Methods
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.loadconsenttext = this.loadconsenttext.bind(this);
    this.handleDisagree = this.handleDisagree.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  loadconsenttext = () => {
    fetch('/static/consent.txt')
    .then((r) => r.text())
    .then(text  => {
      console.log(text);
      this.setState({text: text});
    })
  }

  handleAccept = () => {
    // Close Popup Form
    this.setState({open: false});
    localStorage.setItem('bestbetterappagreetophotorelease', "yes");
  };

  handleDisagree = () => {
    // Redirect to "Safe" 4-H Site
    fetch("/api/v1/about", {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      window.location.href = jsonData.redirect;
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
  }

  componentDidMount() {
    // Load the Text
    this.loadconsenttext();
    var Storage = localStorage.getItem('bestbetterappagreetophotorelease');
    if (Storage === "yes") {
      this.setState({open: false});
    }
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Consent to University of Idaho 4-H Photo Release?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDisagree} color="secondary">Disagree</Button>
            <Button onClick={this.handleAccept} color="secondary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConsentDialogForm;
