import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import './index.css';
import Landing from './public/Landing';
import EventLanding from './public/EventLanding';
import CountyLanding from './public/CountyLanding';
import UploadApp from './public/UploadInterface';
import Admin from './admin/Admin';
import LoginPortal from './admin/login';
import { RequireToken } from './admin/auth';


const router = createBrowserRouter([
  {path: "/", element: <Landing />},
  {path: "admin", element: <RequireToken><Admin /></RequireToken>},
  {path: "login", element: <LoginPortal />},
  {path: "national", element: <EventLanding selection={"national"} />},
  {path: "national/:event", element: <UploadApp />},
  {path: "state", element: <EventLanding selection={"state"} />},
  {path: "state/:event", element: <UploadApp />},
  {path: "district", element: <EventLanding selection={"district"} />},
  {path: "district/:region", element: <EventLanding selection={"district"} />},
  {path: "district/:region/:event", element: <UploadApp />},
  {path: "county", element: <CountyLanding selection={"county"} />},
  {path: "county/:region", element: <EventLanding selection={"county"} />},
  {path: "county/:region/:event", element: <UploadApp />},
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

