/*******************************************************************************
 * Landing.js
 * 
 * Main landing javascript application definition - a thin wrapper around the
 * other components that are used for the 4-H Photo Uploader.
 ******************************************************************************/
import React, { Component } from "react";
import BestBetterAppBar from './AppBar';
import LandingOptionGroup from "./LandingOptions";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import { getDesignTokens, getSavedThemeMode, setSavedThemeMode } from "../theme";
import { withRouter } from "../router-support";
import "./App.css";

class EventLanding extends Component {
  constructor(props) {
    super(props);

    // Set Default State Options
    this.state = {
      error: null,
      pageLoadComplete: false,
      mode: getSavedThemeMode(),
      theme: createTheme(getDesignTokens(getSavedThemeMode())),
      pageTitle: "",
      albumsPage: "",
      selectEvents: [],
      dataLoadComplete: false,
      regionName: this.props.params.region,
    };

    // Bind Class Method
    this.toggleThemeSetting = this.toggleThemeSetting.bind(this);
    this.setDefaultTheme = this.setDefaultTheme.bind(this);
  }

  // Run any code that needs to be executed on page load.
  componentDidMount() {
    this.setState({pageLoadComplete: true});
    // Load About Information
    fetch("/api/v1/about", {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      this.setState({
        pageTitle: jsonData.title,
        albumsPage: jsonData.albums_redirect,
      })
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
    var requestPath = `/api/v1/selection/${this.props.selection}`;
    if (!!this.props.params.region) {
      requestPath += `/${this.props.params.region}`;
    }
    // Load the Events from Which Selection Can be Made
    fetch(requestPath, {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      this.setState({
        selectEvents: jsonData,
        dataLoadComplete: true,
      })
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
  }

  // Theme Changer Function
  toggleThemeSetting() {
    var newTheme = (this.state.mode === 'light' ? 'dark' : 'light');
    setSavedThemeMode(newTheme);
    this.setState({mode: newTheme});
    this.setState({theme: createTheme(getDesignTokens(newTheme))});
  }

  // Theme Default Setter
  setDefaultTheme(themePreference) {
    // Set the default color profile - only if we haven't done so before!
    if (!this.state.pageLoadComplete) {
      var defaultTheme = getSavedThemeMode();
      if (defaultTheme === null){
        defaultTheme = (themePreference ? 'dark' : 'light');
        setSavedThemeMode(defaultTheme);
      }
      this.setState({mode: defaultTheme});
      this.setState({theme: createTheme(getDesignTokens(defaultTheme))});
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        <CssBaseline />
        <div className="App">
          <BestBetterAppBar
            title={this.state.pageTitle}
            albumsRedirect={this.state.albumsPage}
            mode={this.state.mode}
            onLoad={this.setDefaultTheme}
            onThemeChange={this.toggleThemeSetting}
            useQrCode={true}
          />
          {!this.state.dataLoadComplete && <CircularProgress />}
          <LandingOptionGroup
            eventsList={this.state.selectEvents}
            dataLoadComplete={this.state.dataLoadComplete}
          />
        </div>
      </ThemeProvider>
    );
  }
}

export default withRouter(EventLanding);
