import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import "./App.css";

export default function EventCard({
  eventBrief, // Short name of event as it should appear as heading
  eventName="", // Name of Event as it should appear
  pathLink, // Link of Path to Direct
  eventImageId, // URL to the Image to Loaded
}) {
  const [hovered, setHovered] = useState(false);

  const navigate = () => {
    // Absolute Path
    if (pathLink.startsWith('/')) {
      window.location.href = pathLink;
      return;
    }
    // Relative Path
    const host_url = new URL(window.location.href);
    const new_url = new URL(host_url.pathname + "/" + pathLink, host_url);
    window.location.href = new_url.href;
  }

  return (
    <Card
      onClick={navigate}
      onMouseOut={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
      style={{
        transform: `${hovered ? 'scale(1.2,1.2)' : 'scale(1,1)'}`,
        zIndex: `${hovered ? '999' : '1'}`
      }}
    >
      <CardActionArea>
        {(eventImageId !== "" && !!eventImageId) &&
          <CardMedia
          component="img"
          height="240"
          image={`https://albums.idaho4h.org/api/Photo::getArchive?photoIDs=${eventImageId}&kind=SMALL`}
          alt="event-image"
          style={{
            transform: `${hovered ? 'scale(1.2,1.2)' : 'scale(1,1)'}`,
            zIndex: `${hovered ? '999' : '1'}`
          }}
        />
        }
        <CardContent color="primary.main">
          <br/>
          <Typography gutterBottom variant="h5" component="div">
            {(!!eventBrief) ? eventBrief : eventName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {(!!eventBrief) ? eventName : ""}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
