/*******************************************************************************
 * LogViewer - A Log Viewing Component to Display Server Logs.
 ******************************************************************************/

import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { fetchToken } from '../auth';
import axios from 'axios';
import Highlight from 'react-highlight.js';

export default function ServerLogsView() {

  const [logs, setLogs] = React.useState("");
  
  // Establish Connection
  const stream_url = `${window.location.origin}/api/v1/logs/?token=${fetchToken()}`;

  React.useEffect(()=>{
    // Load Requisites when page Completes
    axios.get(stream_url, {
      headers: {
        'Accept': 'application/json',
        //'Authorization': `Bearer ${fetchToken()}` // No Auth Required
      },
    }).then(res => res.data).then(jsonData => {
      // Set the General Configuration Data
      console.log(jsonData);
      setLogs(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  },[stream_url]);

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `photo-server-log.txt`;
    link.href = stream_url;
    link.click();
  };

 return (
  <>
    <Box m={0} sx={{ flexGrow: 1, width: "70vw", height: "78vh", overflow: 'auto'}}>
      <Highlight language="unicorn-rails-log">
        {logs}
      </Highlight>
    </Box>
    <br />
    <Button
      onClick={onDownload}
      variant="contained"
      color="primary"
      startIcon={<FileDownloadIcon />}
    >
      Download Log File
    </Button>
  </>
 )
}