/*******************************************************************************
 * AppBar.js
 * 
 * Application bar (header-bar) to provide selection options for the various
 * "things" that should be accessible in the application.
 ******************************************************************************/
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import { QRCodeCanvas } from 'qrcode.react';

function PageQrCode({open, onClickClose}) {
  const ref = React.useRef();
  const location = window.location.href;
  const [computedWidth, setComputedWidth] = React.useState();

  React.useEffect(()=>{
    calculateQRWidth(window.innerWidth)
    window.addEventListener(
      'resize',
      ()=>calculateQRWidth(window.innerWidth)
    );
  },[]);

  const calculateQRWidth = (innerWidth) => {
    let maxWidth = innerWidth * 11/16;
    setComputedWidth(Math.min(maxWidth, 500));
  }

  const downloadQRCode = () => {
    const canvas = document.querySelector('.qr-code-canvas > canvas');
    var link = document.createElement('a');
    var name = decodeURI(window.location.pathname.replace('/', '-'));
    link.download = `qr${name}.png`;
    link.href = canvas.toDataURL();
    link.click();
    link.remove();
  }

  return (
    <Dialog
      open={open}
      onClose={onClickClose}
      aria-labelledby="qr-code-link-dialog-title"
      aria-describedby="qr-code-link-dialog-description"
    >
      <DialogTitle id="qr-code-link-dialog-title">
        {"QR Code Sharable Link"}
      </DialogTitle>
      <DialogContent>
        <div id="qr-code-canvas" className="qr-code-canvas">
          <QRCodeCanvas
            itemRef={ref}
            value={location}
            size={computedWidth}
            level='H'
            includeMargin={true}
            imageSettings={
              (computedWidth < 72 * 3)
              ? null
              : {
                src:"/static/react/clover-72x72.png",
                height: `${Math.min(computedWidth/10, 72)}`,
                width: `${Math.min(computedWidth/10, 72)}`,
                excavate:true,
              }
            }
          />
        </div>
        <Typography variant='body1' align='center'>
          {location}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={downloadQRCode} color="secondary">
          Download
        </Button>
        <Button onClick={onClickClose} color="secondary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function BestBetterAppBar({
  title,
  albumsRedirect,
  mode,
  onLoad,
  onThemeChange, // called every time the user clicks the LightDarkSwitch
  useQrCode = false, // control to turn on QR code generator option
  useLinkAlbumId = "", // control to specify an album ID for linking
}) {
  const [qrOpen, setQrOpen] = React.useState(false);

  // Function to help secure usage of `_blank`
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openAlbums = () => {
    openInNewTab(albumsRedirect);
    handleMenuClose();
  };
  const openThisAlbum = () => {
    openInNewTab(albumsRedirect + "/gallery#" + useLinkAlbumId);
    handleMenuClose();
  }
  const openRepo = () => {
    openInNewTab("https://gitlab.stanleysolutionsnw.com/idaho4h/4HPhotoUploader");
    handleMenuClose();
  }
  const openAdmin = () => {
    window.location.href = "/admin";
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const prefersDarkMode = useMediaQuery(
    '(prefers-color-scheme: dark)',
    { noSsr: true }
  );

  onLoad(prefersDarkMode);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <PageQrCode open={qrOpen} onClickClose={() => {setQrOpen(false)}}/>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            id="menu-button"
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={openAlbums}>View Albums</MenuItem>
            {useLinkAlbumId !== "" &&
            <MenuItem onClick={openThisAlbum}>Explore This Album</MenuItem>
            }
            <MenuItem onClick={openAdmin}>Settings</MenuItem>
            <MenuItem onClick={openRepo}>Repository</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link href="/" underline="none" color="inherit">
              {title}
            </Link>
          </Typography>
          <div>
            {useQrCode &&
              <Tooltip title="Get QR code for this page.">
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => {setQrOpen(true)}}
                  color="inherit"
                >
                  <QrCode2Icon />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={mode === 'dark' ? "Light Mode" : "Dark Mode"}>
              <IconButton sx={{ ml: 1 }} onClick={onThemeChange} color="inherit">
                {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
