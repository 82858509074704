/*******************************************************************************
* Admin Navigation
*******************************************************************************/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
// Icons
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import GroupsIcon from '@mui/icons-material/Groups';
import MailIcon from '@mui/icons-material/Mail';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import LaunchIcon from '@mui/icons-material/Launch';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import StorageIcon from '@mui/icons-material/Storage';
import UploadIcon from '@mui/icons-material/Upload';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// End Icons
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { clearToken } from './auth';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function BestBetterAdminNavigator({
  mode, // light/dark mode
  pageTitle, // the page title
  onLoad, // on-load callback
  onThemeChange, // callback when theme changes
  onPageChange, // callback when the page changes
  albumsRedirect, // redirect link for albums/gallery view
}) {
  const [drawerOpen, setDrawerOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handlePageChange = (pageName) => {
    // Push the State to change the URL (Without Reloading Page)
    window.history.pushState(
      pageName,
      decodeURI(pageName),
      `/admin?view=${pageName}`
    );
    onPageChange(); 
  }


  const onLogout = () => {
    // Clear active token (to log out)
    clearToken();
    // and return to app-interface
    window.location.href = "/";
  }

  const topNavigation = [
    {'description': 'General', 'icon': <SettingsApplicationsIcon />,},
    {'description': 'District Names', 'icon': <GroupsIcon />,},
    //{'description': 'County Names', 'icon': <Diversity2Icon />,},
    {'description': 'National Events', 'icon': <CalendarMonthIcon />},
    {'description': 'State Events', 'icon': <CalendarMonthIcon />},
    {'description': 'District Events', 'icon': <TodayIcon />},
    {'description': 'County Events', 'icon': <EventIcon />}
  ];
  const middleNavigation = [
    {'description': 'Admin Account', 'icon': <AdminPanelSettingsIcon />},
    {'description': 'Email Settings', 'icon': <MailIcon />},
    {'description': 'Photo Storage', 'icon': <SettingsSuggestIcon />},
    {'description': 'Server Logs', 'icon': <StorageIcon />}
  ];
  const bottomNavigation = [
    {
      'description': 'Upload Interface',
      'icon': <UploadIcon />,
      'href': "/",
    },
    {
      'description': 'Gallery',
      'icon': <LaunchIcon />,
      'href': albumsRedirect,
    },
  ];

  const prefersDarkMode = useMediaQuery(
    '(prefers-color-scheme: dark)',
    { noSsr: true }
  );

  onLoad(prefersDarkMode);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={drawerOpen}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
          >
            { drawerOpen ? <MenuOpenIcon /> : <ListIcon /> }
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Admin Settings{pageTitle.length > 0 ? ` - ${pageTitle}` : ''}
          </Typography>
          <div>
            <Tooltip title="Log Out">
              <IconButton sx={{ ml: 1 }} onClick={onLogout} color="inherit">
                <LogoutIcon />
              </IconButton>
              </Tooltip>
            <IconButton sx={{ ml: 1 }} onClick={onThemeChange} color="inherit">
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          {topNavigation.map((navInfo, _) => (
            <ListItem key={navInfo.description} disablePadding>
              <ListItemButton onClick={()=>{handlePageChange(navInfo.description);}}>
                <ListItemIcon>
                  {navInfo.icon}
                </ListItemIcon>
                <ListItemText primary={navInfo.description} sx={{ opacity: drawerOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {middleNavigation.map((navInfo, _) => (
            <ListItem key={navInfo.description} disablePadding>
              <ListItemButton onClick={()=>{handlePageChange(navInfo.description);}}>
                <ListItemIcon>
                  {navInfo.icon}
                </ListItemIcon>
                <ListItemText primary={navInfo.description} sx={{ opacity: drawerOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {bottomNavigation.map((navInfo, _) => (
            <ListItem key={navInfo.description} disablePadding>
              <ListItemButton href={navInfo.href}>
                <ListItemIcon>
                  {navInfo.icon}
                </ListItemIcon>
                <ListItemText primary={navInfo.description} sx={{ opacity: drawerOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      </Box>
    </Box>
  );
}
