/*******************************************************************************
 * Landing.js
 * 
 * Main landing javascript application definition - a thin wrapper around the
 * other components that are used for the 4-H Photo Uploader.
 ******************************************************************************/
import React, { Component } from "react";
import BestBetterAppBar from './AppBar';
import LandingOptionGroup from "./LandingOptions";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { getDesignTokens, getSavedThemeMode, setSavedThemeMode } from "../theme";
import "./App.css";

export default class Landing extends Component {
  constructor(props) {
    super(props);

    // Set Default State Options
    this.state = {
      error: null,
      pageLoadComplete: false,
      mode: getSavedThemeMode(),
      theme: createTheme(getDesignTokens(getSavedThemeMode())),
      pageTitle: "",
      albumsPage: "",
    };

    // Bind Class Method
    this.toggleThemeSetting = this.toggleThemeSetting.bind(this);
    this.setDefaultTheme = this.setDefaultTheme.bind(this);
  }

  // Run any code that needs to be executed on page load.
  componentDidMount() {
    this.setState({pageLoadComplete: true});
    fetch("/api/v1/about", {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      this.setState({
        pageTitle: jsonData.title,
        albumsPage: jsonData.albums_redirect,
      })
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
  }

  // Theme Changer Function
  toggleThemeSetting() {
    var newTheme = (this.state.mode === 'light' ? 'dark' : 'light');
    setSavedThemeMode(newTheme);
    this.setState({mode: newTheme});
    this.setState({theme: createTheme(getDesignTokens(newTheme))});
  }

  // Theme Default Setter
  setDefaultTheme(themePreference) {
    // Set the default color profile - only if we haven't done so before!
    if (!this.state.pageLoadComplete) {
      var defaultTheme = getSavedThemeMode();
      if (defaultTheme === null){
        defaultTheme = (themePreference ? 'dark' : 'light');
        setSavedThemeMode(defaultTheme);
      }
      this.setState({mode: defaultTheme});
      this.setState({theme: createTheme(getDesignTokens(defaultTheme))});
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        <CssBaseline />
        <div className="App">
          <BestBetterAppBar
            title={this.state.pageTitle}
            albumsRedirect={this.state.albumsPage}
            mode={this.state.mode}
            onLoad={this.setDefaultTheme}
            onThemeChange={this.toggleThemeSetting}
            useQrCode={true}
          />
          <LandingOptionGroup eventsList={[
            {name: "", slug: "National Events", path: "/national", image_id: ""},
            {name: "", slug: "State Events", path: "/state", image_id: ""},
            {name: "", slug: "District Events", path: "/district", image_id: ""},
            {name: "", slug: "County Events", path: "/county", image_id: ""},
          ]}/>
        </div>
      </ThemeProvider>
    );
  }
}
