/*******************************************************************************
 * Landing.js
 * 
 * Main landing javascript application definition - a thin wrapper around the
 * other components that are used for the 4-H Photo Uploader.
 ******************************************************************************/
import React, { Component } from "react";
import BestBetterAppBar from './AppBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Box,
  Chip,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { getDesignTokens, getSavedThemeMode, setSavedThemeMode } from "../theme";
import "./App.css";

export default class CountyLanding extends Component {
  constructor(props) {
    super(props);

    // Set Default State Options
    this.state = {
      error: null,
      pageLoadComplete: false,
      mode: getSavedThemeMode(),
      theme: createTheme(getDesignTokens(getSavedThemeMode())),
      pageTitle: "",
      albumsPage: "",
      selectOptions: {},
    };

    // Bind Class Method
    this.toggleThemeSetting = this.toggleThemeSetting.bind(this);
    this.setDefaultTheme = this.setDefaultTheme.bind(this);
  }

  // Run any code that needs to be executed on page load.
  componentDidMount() {
    this.setState({pageLoadComplete: true});
    // Load About Information
    fetch("/api/v1/about", {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      this.setState({
        pageTitle: jsonData.title,
        albumsPage: jsonData.albums_redirect,
      })
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
    // Load the Events from Which Selection Can be Made
    fetch(`/api/v1/selection/${this.props.selection}`, {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      this.setState({selectOptions: jsonData});
      console.log(this.state.selectOptions)
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
  }

  // Theme Changer Function
  toggleThemeSetting() {
    var newTheme = (this.state.mode === 'light' ? 'dark' : 'light');
    setSavedThemeMode(newTheme);
    this.setState({mode: newTheme});
    this.setState({theme: createTheme(getDesignTokens(newTheme))});
  }

  // Theme Default Setter
  setDefaultTheme(themePreference) {
    // Set the default color profile - only if we haven't done so before!
    if (!this.state.pageLoadComplete) {
      var defaultTheme = getSavedThemeMode();
      if (defaultTheme === null){
        defaultTheme = (themePreference ? 'dark' : 'light');
        setSavedThemeMode(defaultTheme);
      }
      this.setState({mode: defaultTheme});
      this.setState({theme: createTheme(getDesignTokens(defaultTheme))});
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        <CssBaseline />
        <div className="App">
          <BestBetterAppBar
            title={this.state.pageTitle}
            albumsRedirect={this.state.albumsPage}
            mode={this.state.mode}
            onLoad={this.setDefaultTheme}
            onThemeChange={this.toggleThemeSetting}
            useQrCode={true}
          />
          <Grid
            container
            spacing={0.5}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '90vh' }}
          >
            {(Object.keys(this.state.selectOptions).length === 0) && <>
              <br />
              <Typography >
                There are no configured events for this category.
                If this is not expected, contact your site administrator.
              </Typography>
            </>}
            {(Object.keys(this.state.selectOptions).length !== 0) && <>
              <Grid item xs={6}>
                {Object.entries(this.state.selectOptions).map(([key, _]) => (
                  <Chip label={key} component="a" href={`#${key}`} clickable />
                ))}
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ minWidth: 360, maxWidth: 1400 }}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '85vh',
                      '& ul': { padding: 0 },
                    }}
                    subheader={<li />}
                  >
                    {Object.entries(this.state.selectOptions).map(
                      ([key, list_values]) => (
                        <li key={`section-${key}`}>
                          <ul>
                            <ListSubheader
                              id={key}
                              sx={{
                                bgcolor: 'primary.main',
                                color: 'primary.text',
                              }}
                            >
                              {key}
                            </ListSubheader>
                            {list_values.map((item) => (
                              <ListItemButton
                                key={`item-${key}-${item}`}
                                component="a"
                                href={`${this.props.selection}/${item}`}
                                sx={{
                                  bgcolor: 'secondary.alt',
                                  color: 'secondary.text',
                                }}
                              >
                                <ListItemText primary={item}/>
                              </ListItemButton>
                            ))}
                          </ul>
                        </li>
                      )
                    )}
                  </List>
                </Box>
              </Grid>
            </>}
          </Grid>
        </div>
      </ThemeProvider>
    );
  }
}
